export const Brukere = { 

    "KnutHoltet": {
      "bilde": "https://media.discordapp.net/attachments/288000110355283968/1141984885670678629/4x.gif",
      "emoji" : "🍞"
    },

    "markusevanger": {
      "bilde": "https://media.tenor.com/GLW-m3S5vjkAAAAd/broom-broom-horse.gif",
      "emoji" : "👑"
    },

    "erlendjoa": {
      "bilde": "https://cdn.discordapp.com/attachments/1022518239030431744/1145021728586727475/bobatyun-taehyun.gif",
      "emoji" : "🥇"
    },

    "zFlamepvpz": {
      "bilde": "https://media.tenor.com/apns_8-5xl8AAAAd/huh-verne.gif",
      "emoji": "👻"
    },

    "bsian": {
      "bilde": "https://cdn.discordapp.com/attachments/1011549038853754911/1145339307855446066/dobert.png",
      "emoji": "👲"
    },

    "handernoah": {
      "bilde": "https://cdn.discordapp.com/attachments/832899170573615104/1145706250366091345/noah_profilbilde_leetcode_crop.gif",
      "emoji": "🍉"
    },

    "Mariisno": {
      "bilde": "https://cdn.discordapp.com/attachments/1032561544069906462/1146040044595974164/20230719_230015.jpg",
      "emoji": "🍉"
    },

    "dangosnail": {
      "bilde": "https://cdn.discordapp.com/attachments/1013738125706657844/1146040292290609162/IMG_3870.gif",
      "emoji": "🔱"
    },

    "MemMite": {
      "bilde": "https://media.discordapp.net/attachments/852770651811086346/1174128192483766373/400163a04ec3c2109d3e4d9cfcebea49.jpg?ex=65667717&is=65540217&hm=83843c0c07bf5f0587894f5dd78a8f4fd859868aa8fd6ff698b696179576454b&=",
      "emoji": "🍟"
    },

  }