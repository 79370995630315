import Board from './components/board';
import "./components/style.css";

function App() {
  return (
    <div className="App">
      <Board></Board>
    </div>


  );
}
export default App;
